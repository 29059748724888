<template>
    <div class="patrolrecording-page">
        <van-collapse v-model="activeNames">
            <van-collapse-item :name="index" v-for="(item, index) in dataList">
                <template #title>
                    <div>{{ item.name }}</div>
                </template>
                <template #value>
                    <div>{{ item.created_at }}</div>
                </template>
                <!--<p>设备编号:SPB000000123</p>-->
                <!--<p>序列号:DW-X20220115-001</p>-->
                <!--<p>打卡编号:DKBH11</p>-->
                <p>规则天数：{{ item.task_day }}</p>
                <p>规则次数：{{ item.task_count }}</p>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_PATROL_LIST } from "@/api/patrol";

onMounted(() => {
    getDataList();
});

let activeNames = ref([]);

// 获取列表
let dataList = ref([]);

let getDataList = () => {
    GET_PATROL_LIST({
        limit: 10000
    }).then((res) => {
        dataList.value = res.data.data.list;
        console.log(res.data.data);
    });
};
</script>

<style lang="scss" scoped>
.patrolrecording-page {
    min-height: 100vh;
    background: #f0f0f0;
}
</style>
